import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';

import { Toast } from 'primereact/toast';

import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { getUrl, getJobTimeFormat, formatDate } from '../planner/planner';

export class Travellers extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			travellers: [],
			isLoaded: false,
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			globalFilterValue: '',
			shouldUpdate: false,
			tableCount: 0,
			columns: [],
			expandedRows: [],
			visibleColumns: [],
			errorToast: React.createRef()
		}

		let _columns = [
			{ field: 'created_date', header: 'Created Date', sortable: true },
			{ field: 'number', header: 'Order #', sortable: true },
			{ field: 'company_name', header: 'Customer', sortable: true },
			{ field: 'ac_number', header: 'A/C', sortable: true },
			{ field: 'reference', header: 'Customer Order #', sortable: true },
			{ field: 'product_title', header: 'Products', sortable: true },
			{ field: 'order_time', header: 'Total Target Time', sortable: true },
		];

		if(this.props.status !== 'completed' && this.props.status !== 'cancelled') {
			_columns.push(
				{ field: 'date_confirmed', header: 'Confirmed Date', sortable: false },
			)
		}

		if(this.props.status !== 'prepared') {
			_columns.push(
				{ field: 'time', header: 'Total Recorded Time', sortable: true },
			)
		}

		if(this.props.status != 'prepared' && this.props.status != 'completed' && this.props.status != 'cancelled') {
			_columns.push(
				{ field: 'progressbar', header: 'Progress', sortable: false },
			)
		}

		if(this.props.status == 'completed') {
			_columns.push(
				{ field: 'date_completed', header: 'Completed Date', sortable: false },
			)
		}

		if(this.props.status == 'cancelled') {
			_columns.push(
				{ field: 'date_cancelled', header: 'Cancelled Date', sortable: false },
			)
		}

		this.state.columns = _columns;

		this.getOrder = this.getOrder.bind(this);
		this.getProductTitle = this.getProductTitle.bind(this);
		this.editTravellerButton = this.editTravellerButton.bind(this);
		this.getAccountNumber = this.getAccountNumber.bind(this);
	}

	/**
	 * When the component isLoaded c = this.state;reated get the data from the REST API and set the columns based on the current users preferences
	 */
	componentDidMount() {
		document.title = this.props.pageTitle + ' - Planner';

		this.updateTravellers();

		const queryParams = new URLSearchParams(window.location.search);

		let traveller;

		traveller = queryParams.get('traveller');

		if(queryParams.get('filter')) {
			traveller = queryParams.get('filter');
		}

		if(traveller !== null) {
			let _filters = { ...this.state.filters };

			_filters['global'].value = traveller;

			this.setState({
				globalFilterValue: traveller,
				filters: _filters
			});
		}

		// If the user has selected custom columns, pre load these or use the default.
		if(localStorage.getItem('visibleColumnsTravellerArchive')) {
			this.setState({
				visibleColumns: JSON.parse(localStorage.getItem('visibleColumnsTravellerArchive'))
			});
		} else {
			this.setState({
				visibleColumns: this.state.columns
			});
		}
	}

	/**
	 * Checks if the component should update based on if an product category has changed or the props for the component to reload
	 * 
	 * @param {Object} prevProps The previous state of the props
	 * @param {Object} prevState The previous state of the state
	 */
	componentDidUpdate(prevProps, prevState) {
		if(this.props.pageTitle !== prevProps.pageTitle || this.props.status !== prevProps.status || this.state.shouldUpdate !== prevState.shouldUpdate) {

			let _columns = [
				{ field: 'number', header: 'Order #', sortable: true },
				{ field: 'company_name', header: 'Customer', sortable: true },
				{ field: 'ac_number', header: 'A/C', sortable: true },
				{ field: 'reference', header: 'Customer Order #', sortable: true },
				{ field: 'product_title', header: 'Products', sortable: true },
				{ field: 'order_time', header: 'Total Target Time', sortable: true },
			];

			if(this.props.status !== 'completed' && this.props.status !== 'cancelled') {
				_columns.push(
					{ field: 'date_confirmed', header: 'Confirmed Date', sortable: false },
				)
			}

			if(this.props.status !== 'prepared') {
				_columns.push(
					{ field: 'time', header: 'Total Recorded Time', sortable: true },
				)
			}
	
			if(this.props.status != 'prepared' && this.props.status != 'completed' && this.props.status != 'cancelled') {
				_columns.push(
					{ field: 'progressbar', header: 'Progress', sortable: false },
				)
			}
	
			if(this.props.status == 'completed') {
				_columns.push(
					{ field: 'date_completed', header: 'Completed Date', sortable: false },
				)
			}
	
			if(this.props.status == 'cancelled') {
				_columns.push(
					{ field: 'date_cancelled', header: 'Cancelled Date', sortable: false },
				)
			}

			this.setState({
				columns: _columns
			});

			this.updateTravellers();

			this.setState({shouldUpdate: false, isLoaded: false, columns: _columns});
			document.title = this.props.pageTitle + ' - Planner';

			let _filters = { ...this.state.filters };

			_filters['global'].value = '';

			this.setState({
				globalFilterValue: '',
				filters: _filters
			});
		}
	}

	/**
	 * Gets the travellers for the DataTable
	 */
	updateTravellers() {
		var url = getUrl() + '/wp-json/planner/v1/travellers';

		if(this.props.status) {
			url += '?status=' + this.props.status;
		}

		axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then(res => this.setState({
			travellers: res.data,
			tableCount: res.data.length,
			isLoaded: true
		})).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });

			console.log(err)
		});
	}

	/**
	 * Create an array of all of the travellers
	 * 
	 * @returns An array containing traveller information for the Datatable
	 */
	getTravellers() {
		const tableRows = [];

		this.state.travellers.forEach(traveller => {
			tableRows.push({
				id: traveller.id,
				traveller_number: traveller.traveller_number,
				number: traveller.order_number,
				order_id: traveller.order_id,
				product_id: traveller.product_id,
				order_time: traveller.total_target_time,
				ac_number: traveller.order_ac_number,
				time: traveller.total_recorded_time,
				reference: traveller.customer_order_number,
				company_name: traveller.order_company,
				product_title: traveller.product_name,
				sku: traveller.product_sku,
				complete_traveller: traveller.date_completed,
				quantity: traveller.product_qty,
				confirmed_date: traveller.date_confirmed,
				title: "Traveller #" + traveller.traveller_number,
				date_created: traveller.date_created,
				isLocked: traveller.locked,
				cancelled_time: traveller.date_cancelled,
				status: traveller.traveller_status,
				drawing_url: traveller.product_drawing_url,
				notes: traveller.notes
			})
		});

		return tableRows;
	}

	/* Notes */

	/**
	 * Toggle the expanded rows for the jobs
	 */
	toggleExpandRows() {
		let _expandedRows = {};

		if(this.state.expandedRows.length === 0) {
			this.state.orders.forEach((p) => (_expandedRows[`${p.id}`] = true));

			this.setState({
				expandedRows: _expandedRows
			});
		} else {
			this.setState({
				expandedRows: []
			});
		}
	}

	/**
	 * Return the current index of the note
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @param {Object} props The properties associated to the row
	 * @returns The current row number (+1 as rows start from 0)
	 */
	getNoteNumber(rowData, props) {
		return rowData.notes - props.rowIndex;
	}

	/**
	 * Gets the order number and count of notes to be displayed under the sales order number
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML For the order number including the note count
	 */
	getTravellerNumber(rowData) {
		let noteCount = 0;

		if(rowData.notes.length) {
			noteCount = rowData.notes.length
		}

		return <><p className="mb-0 d-flex align-items-center">{rowData.traveller_number}</p><div className="d-flex"><small>({noteCount} Notes)</small></div></>;
	}

	/**
	 * Generate the template for the functionality when a user clicks to expand a row.
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The template for the row epansion which includes all of the notes for the order
	 */
		rowExpansionTemplate(rowData) {
			const notes = [];
	
			if(rowData.notes) {
				// Loop through all notes on the order
				rowData.notes.map(order =>
					notes.push({
						id: order.user_id,
						date_created: formatDate(order.comment_date, true),
						content: order.comment_content,
						added_by: order.comment_author,
						added_by_id: order.user_id,
						notes: rowData.notes.length
					})
				);
			}
	
			return (
				<DataTable value={notes} className="mx-5 mt-2" paginator rows={10}>
					<Column field="id" header="Note #" body={this.getNoteNumber} headerStyle={{ width: '6rem' }}></Column>
					<Column field="date_created" header="Date" headerStyle={{ width: '10rem' }}></Column>
					<Column field="content" header="Note" body={this.getNoteContent}></Column>
					<Column field="added_by" header="Author" body={this.getNoteAuthor} headerStyle={{ width: '8rem' }}></Column>
				</DataTable>
			);
		}
	

	getAccountNumber(rowData) {
		return <Link to="#" onClick={ e => {
			let _filters = { ...this.state.filters };

			_filters['global'].value = rowData.ac_number;

			this.setState({filters: _filters, globalFilterValue: rowData.ac_number })

		}}>{rowData.ac_number}</Link>
	}

	/**
	 * Update the global filtering to filter table contents
	 * 
	 * @param {input} e Information related to the current input
	 */
	onGlobalFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['global'].value = value;

		this.setState({filters: _filters, globalFilterValue: value })
	}

	/**
	 * Get the total time the job should take to complete
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns Time formatting for the total time the job should take to complete
	 */
	getOrderTime(rowData) {
		if(rowData.order_time !== 0) {
			return <>
					<span className="bg-dark badge text-white"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{getJobTimeFormat(rowData.order_time)}</span>
			</>
		} else {
			return <span className="bg-danger badge text-white">Missing Time</span>
		}
	}

	/**
	 * Get the current time spent on the job
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns Time formatting for the time spent on the job
	 */
	getCurrentTime(rowData) {
		let badgeClass = 'bg-success';

		if(rowData.time > rowData.order_time) {
			badgeClass = 'bg-warning';
		}

		if(rowData.time > (rowData.order_time + ((rowData.order_time / 100) * 5))) {
			badgeClass = 'bg-danger';
		}

		let currentTime = <span className="bg-dark badge text-white"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>Not Started</span>;

		if(rowData.time !== "0") {
			currentTime = <>
					<Tooltip autoHide={false} target=".fudge-ratio" />
					<span className={badgeClass + " badge text-white fudge-ratio"} data-pr-tooltip={"Fudge Ratio: " + getJobTimeFormat(rowData.time * 1.0833)}
						data-pr-position="right"
						data-pr-at="right+5 center"
						data-pr-my="left center"><i className="pi pi-stopwatch me-1" style={{ fontSize: '0.65rem' }} ></i>{getJobTimeFormat(rowData.time)}</span>
			</>
		}

		return currentTime;
	}

	/**
	 * Get the progress bar for the traveller completition percentage
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns The progress bar for the traveller completition
	 */
	getProgressBar(rowData) {
		let progressBar = <ProgressBar value={0}></ProgressBar>;

		if(rowData.time && rowData.order_time) {
			let value = (rowData.time / rowData.order_time) * 100;

			if(value > 100) {
				value = 100;
			}

			progressBar = <ProgressBar value={Math.round(value)}></ProgressBar>	
		}

		return progressBar;
	}

	/**
	 * The product title with a link to the product
	 * 
	 * @param {Object} rowData The data associated to the current row
	 * @returns clickable product title to the individual product page
	 */
	getProductTitle(rowData) {
		if(this.props.capabilities.edit_products === true) {
			return <div className="d-block" key={rowData.product_title}><Tooltip autoHide={false} target=".product-description" /><Link data-pr-tooltip={rowData.product_title} data-pr-position="left" className="product-description" to={'/product/' + rowData.product_id}>{rowData.sku}</Link> <span className="badge badge-primary bg-primary">x {rowData.quantity}</span> 
				{
					this.props.capabilities.view_drawings && (
						<a target="_blank" href={rowData.drawing_url} ><i className="ms-1 pi pi-file-pdf" style={{ fontSize: '0.8rem' }}></i></a>
					)
				}
			</div> 
		} else {
			return <div className="d-block" key={rowData.sku}><Tooltip autoHide={false} target=".product-description" /><span data-pr-tooltip={rowData.product_title} data-pr-position="left" className="product-description">{rowData.sku}</span> <span className="badge badge-primary bg-primary">x {rowData.quantity}</span> 
				{
					this.props.capabilities.view_drawings && (
						<i className="ms-1 pi pi-file-pdf" style={{ fontSize: '0.8rem' }}></i>
					)
				}
			</div> 
		}
	}

	/**
	 * The job id with a link to the order
	 * 
	 * @param {Object} rowData The data associated to the current row 
	 * @returns The link with the job id
	 */
	getOrder(rowData) {
		if(this.props.capabilities.edit_jobs === true || this.props.capabilities.view_jobs === true) {
			return <Link to={'/job/' + rowData.order_id}>{rowData.number}</Link>
		} else {
			return rowData.number;
		}
	}

	/**
	 * Get the url for editing the traveller
	 * 
	 * @param {Object} rowData The data associated to the current row 
	 * @returns The url for editing the traveller
	 */
	editTravellerButton(rowData) {
		const url = "/traveller/" + rowData.id;
		let icon = <i className="pi pi-lock me-1" style={{ fontSize: '0.8rem' }}></i>;
		let text = 'View';

		// Check if the current row is locked, change the icon and text based on if it is or not
		if((rowData.isLocked === false || rowData.isLocked.ID === this.props.currentLoggedInUser.id) && this.props.capabilities.edit_travellers === true) {
			icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
			text = 'Edit';
		}

		return (<Link to={url} className="btn btn-primary">{icon} {text}</Link>);
	}

	/**
	 * Get the date and time the traveller was created
	 * 
	 * @param {Object} rowData The data associated to the current row 
	 * @returns The formatted date and time of the traveller creation
	 */
	getDate(rowData) {
		return formatDate(rowData.confirmed_date);
	}

	/**
	 * Get the date and time the traveller was created
	 * 
	 * @param {Object} rowData The data associated to the current row 
	 * @returns The formatted date and time of the traveller creation
	 */
	getCreatedDate(rowData) {
		return formatDate(rowData.date_created);
	}

	/**
	 * Get the date and time the traveller was created
	 * 
	 * @param {Object} rowData The data associated to the current row 
	 * @returns The formatted date and time of the traveller creation
	 */
	getCompletedDate(rowData) {
		return formatDate(rowData.complete_traveller);
	}

	/**
	 * Get the date and time the traveller was created
	 * 
	 * @param {Object} rowData The data associated to the current row 
	 * @returns The formatted date and time of the traveller creation
	 */
	getCancelledDate(rowData) {
		return formatDate(rowData.cancelled_time);
	}

	/**
	 * Resets the global filtering back to an empty string
	 */
	clearSearch() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		})
	}

	/**
	 * Sets the state for the selected columns and saves in local storage so it's remembered for future sessions.
	 * 
	 * @param {Event} event The selected column
	 */
	onColumnToggle(event) {
		let selectedColumns = event.value;
		let orderedSelectedColumns = this.state.columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

		this.setState({visibleColumns: orderedSelectedColumns})
		localStorage.setItem('visibleColumnsTravellerArchive', JSON.stringify(orderedSelectedColumns));
	}

	/**
	 * Get the HTML to create the filtering options on the table header
	 * 
	 * @returns The HTML for the header for the table
	 */
	renderHeader() {
		return (
			<div className="container">
				<div className="d-flex justify-content-end">
					{
						<MultiSelect placeholder="Select Job Columns" value={this.state.visibleColumns} options={this.state.columns} onChange={this.onColumnToggle.bind(this)} optionLabel="header" className="me-2 columnToggling" display="chip" />
					}
					<div className="travellers-header">
						<span className="p-input-icon-left p-input-icon-right">
							<i className="pi pi-search" />
							<InputText placeholder="Search..." value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange.bind(this)} />
							{
								this.state.globalFilterValue != null && this.state.globalFilterValue !== '' && (
									<i className="pi pi-times" onClick={this.clearSearch.bind(this)} />
								)
							}
						</span>
					</div>
				</div>
			</div>
		)
	}

	getTravellerTitle() {
		let title = this.props.title

		if(this.props.capabilities.edit_travellers !== true && this.props.capabilities.view_travellers != true) {
			title = 'Access Denied';
		}

		return title;
	}

	render() {
		const { errorToast, isLoaded, filters, visibleColumns, expandedRows } = this.state;

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}

		return (
			<>
				<Toast ref={errorToast} position="center" />
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5">
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center">
						<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
							<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
						</Button>
						<div>
							<h1 className="mb-0 h3">{this.getTravellerTitle()}</h1>
							{
								(this.props.capabilities.edit_travellers === true || this.props.capabilities.view_travellers === true) && (
									<span><strong>Traveller Count:</strong> {this.state.tableCount} of {this.getTravellers().length}</span>
								)
							}
						</div>
					</div>
				</div>
				{
					(this.props.capabilities.edit_travellers === true || this.props.capabilities.view_travellers === true) && ( // https://stackoverflow.com/questions/70682832/
						<div className="card mx-5">
							{
								!isLoaded && (
									<div className={"mx-5 mb-3"} style={{ position: 'absolute', top: '3rem', zIndex: '99999', left: '45%' }}>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
									</div>
								)
							}
							<DataTable expandedRows={expandedRows} onRowToggle={(e) => this.setState({expandedRows: e.data})} rowExpansionTemplate={this.rowExpansionTemplate.bind(this)} className={loadingClass} onValueChange={filteredData => this.setState({tableCount: filteredData.length})} globalFilterFields={['traveller_number', 'number', 'ac_number', 'company_name', 'reference', 'sku']} sortOrder={-1} sortField="id" filters={filters} dataKey="title" size="small" value={this.getTravellers()} tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} header={this.renderHeader(this)}>
								<Column expander={true} style={{ width: '3rem' }} />
								<Column key="traveller_number" field="traveller_number" header="Traveller #" sortable={true} body={this.getTravellerNumber} />
								{visibleColumns.map((col) => {
									// Absolute shit code but LocalStorage won't store the body functions. See if there's a better way to do this.
									if(col.field === 'created_date') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getCreatedDate} />)
									} else if(col.field === 'number') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getOrder} />)
									} else if(col.field === 'ac_number') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getAccountNumber} />)
									} else if(col.field === 'product_title') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getProductTitle} style={{minWidth: '10rem'}} />)
									} else if(col.field == 'date_completed') {
										if(this.props.status === 'completed') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getCompletedDate} />)
										} else {
											return '';
										}
									} else if(col.field === 'date_cancelled') {
										if(this.props.status == 'cancelled') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getCancelledDate} />)
										} else {
											return '';
										}
									} else if(col.field === 'date_confirmed') {
										if(this.props.status !== 'completed' && this.props.status !== 'cancelled') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getDate} />) 
										} else {
											return '';
										}
									} else if(col.field === 'order_time') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getOrderTime} />)
									} else if(col.field === 'time') {
										if(this.props.status !== 'prepared') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getCurrentTime} />); 
										} else {
											return '';
										}
									} else if(col.field === 'progressbar') {
										if(this.props.status != 'prepared' && this.props.status != 'completed' && this.props.status != 'cancelled') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getProgressBar} style={{minWidth: '10rem'}} />)
										} else {
											return '';
										}
									} else {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />)
									}
								})}
								<Column body={this.editTravellerButton} header="" style={{ minWidth: '6rem' }} />
							</DataTable>
						</div>
					)
				}
			</>
		);
	}
}
export default Travellers